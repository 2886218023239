<template>
  <div
    v-if="upData"
    ref="echarts"
    style="width: 100%; display: flex; position: relative"
  ></div>
</template>
<script>
import ChinaGeo from '@/assets/map/json/china.json'
import * as echarts from 'echarts'
import { getAreaByLevel } from '@/http/report'
import _ from 'lodash'
import 'echarts/extension/bmap/bmap'
export default {
  data() {
    return {
      zoom: 5,
      myChart: null,
      upData: true, //默认是渲染的
    }
  },
  mounted() {
    this.initChart()
  },
  methods: {
    async getData(level) {
      return await getAreaByLevel({ level })
    },
    async initChart() {
      const data = await getAreaByLevel({ level: '1' })
      const res = data.map(item => {
        return { name: item.name, value: [item.lat, item.lon, item.areas] }
      })
      console.log('data====', res)
      echarts.registerMap('china', ChinaGeo)
      this.myChart = echarts.init(this.$refs.echarts)
      const option = {
        title: {
          text: '资产地图',
          left: 'center',
          top: 20,
        },
        // geo: {
        //   show: true,
        //   map: 'china',
        //   label: {
        //     normal: {
        //       show: true,
        //     },
        //     emphasis: {
        //       show: true,
        //     },
        //   },
        //   roam: true,
        //   itemStyle: {
        //     emphasis: {
        //       areaColor: '#2B91B7',
        //     },
        //   },
        //   zoom: 1,
        //   scaleLimit: {
        //     min: 1,
        //     max: 8,
        //   },
        // },
        bmap: {
          center: [104.114129, 37.550339],
          zoom: 5,
          roam: true,
        },
        visualMap: {
          type: 'piecewise',
          right: 0,
          backgroundColor: 'rgba(255,255,255,1)',
          pieces: [
            { gt: 10000 },
            { gt: 1000, lte: 10000 },
            { gt: 100, lte: 1000 },
            { gt: 0, lte: 100 },
          ],
        },
        series: [
          {
            name: '点',
            type: 'effectScatter',
            coordinateSystem: 'bmap',
            symbol: 'circle',
            symbolSize: function () {
              return 100
            },

            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke',
            },
            label: {
              formatter: '{b}\r\n{@[2]}平方米',
              show: true,
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: '#333',
            },
            emphasis: {
              scale: true,
            },
            zlevel: 1,

            data: res,
          },
        ],
      }

      // 获取百度地图实例，使用百度地图自带的控件
      this.myChart.setOption(option)

      this.listenerZoom()
    },
    //监听缩放事件
    listenerZoom() {
      const that = this

      this.myChart.on(
        'bmaproam',
        _.debounce(async params => {
          let _option = that.myChart.getOption() //获取最新的option配制
          let _zoom = _option.bmap[0].zoom //获取当前缩放比例zoom
          console.log('_zoom====', _zoom)
          console.log('params===', params)
          if (that.zoom === _zoom) {
            return
          }
          that.zoom = _zoom

          if (params.dy || params.dx) return //如果是拖拽事件则退出

          //这里可以根据自己情况修改_option选项配制。。。。。。
          let data = null

          if (_zoom < 5) {
            data = await that.getData('1')
          } else if (_zoom >= 5 && _zoom < 12) {
            data = await that.getData('2')
          } else {
            data = await that.getData('3')
          }
          console.log('data====', data)
          const res = data.map(item => {
            return { name: item.name, value: [item.lat, item.lon, item.areas] }
          })
          _option.series[0].data = res
          that.myChart.clear()
          that.myChart.setOption(_option)
          // that.upData = false
          // that.$nextTick(() => {
          //   console.log('fffff')
          //   that.upData = true
          // })
        }, 500)
      )
    },
  },
}
</script>
