import { axios } from '../util/request'

/**
 * sdf
 *
 * @param {*} parameter sdf
 * @returns {*} sdf
 */
export function getAreaByLevel(parameter) {
  return axios({
    url: '/property/project/getAreaByLevel',
    method: 'get',
    params: parameter,
  })
}
