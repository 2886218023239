import axios from 'axios'
import router from '../router'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
const baseURL = process.env.VUE_APP_API_BASE_URL
// 创建 axios 实例
const service = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 60000, // 请求超时时间
  withCredentials: true,
})

const err = error => {
  if (error.response) {
    const data = error.response.data
    console.log(data)
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  // const token = Vue.ls.get(ACCESS_TOKEN)

  console.log('config.data=====', config)
  // config.post['Content-Type'] = 'application/x-www-form-urlencoded'
  if (config.headers['Content-Type'] !== 'multipart/form-data') {
    config.data = {
      ...(config.data || {}),
      _t: +new Date(),
    }
    config.params = {
      ...(config.params || {}),
      _t: +new Date(),
    }
  }
  console.log('config.data=', config)

  return config
}, err)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.config.responseType == 'blob') {
      console.log('response==>', response)
      if (response.status == 200) {
        if (response.data.type === 'application/json') {
          //解析blob类型数据
          const blob = new Blob([response.data], {
            type: 'application/json',
          })

          //将Blob 对象转换成字符串
          var reader = new FileReader()
          reader.readAsText(blob, 'utf-8')
          reader.onload = function () {
            console.info(reader.result)
            const res = JSON.parse(reader.result)
            notification.error({
              message: '错误',
              description: res.message,
            })
          }
          return Promise.reject('error')
        } else {
          return response
        }
      } else {
        notification.error({
          message: '错误',
          description: '导出发生异常',
        })
        return Promise.reject('error')
      }
    }

    const code = response.data.code
    console.log('hshs', response)

    if (code === 500) {
      notification.error({
        message: '错误',
        description: response.data.message,
      })
      return Promise.reject(response.data)
    }
    if (code === 600) {
      notification.error({
        message: '错误',
        description: '请登录',
      })
      router.replace({ path: '/login' })

      return Promise.reject(response.data)
    }
    if (code === 601) {
      notification.error({
        message: '错误',
        description: '会话失效，请重新登陆',
      })
      router.replace({ path: '/login' })

      return Promise.reject(response.data)
    }
    return Promise.resolve(response.data.data.response)
  },
  err => {
    console.log(err)
    notification.error({
      message: '错误',
      description: '接口调用超时',
    })
    return Promise.reject(new Error('接口调用超时'))
  }
)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  },
}

export { installer as VueAxios, service as axios, baseURL }
